import { CustomDataDto, Error, LoginDto, ResponseDto } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class LoginController<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    /**
     * No description
     *
     * @tags login-controller
     * @name Login
     * @summary 로그인
     * @request POST:/login
     * @response `200` `LoginDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    login = (
        query: {
            /** 로그인 아이디 */
            username: string
            /** 로그인 암호 */
            password: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<LoginDto, Error>({
            path: `/login`,
            method: 'POST',
            query: query,
            ...params,
        })
    /**
     * No description
     *
     * @tags login-controller
     * @name Join
     * @summary 회원가입 (테스트용)
     * @request POST:/join
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    join = (
        query: {
            /** 로그인 아이디 */
            username: string
            /** 로그인 암호 */
            password: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ResponseDto, Error>({
            path: `/join`,
            method: 'POST',
            query: query,
            ...params,
        })
    /**
     * No description
     *
     * @tags login-controller
     * @name CardLogin
     * @summary 로그인
     * @request POST:/card-login
     * @response `200` `LoginDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    cardLogin = (
        query: {
            /** 카드키 */
            cardKey: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<LoginDto, Error>({
            path: `/card-login`,
            method: 'POST',
            query: query,
            ...params,
        })
    /**
     * No description
     *
     * @tags login-controller
     * @name GetCustomData
     * @summary customdata 용
     * @request GET:/info
     * @response `200` `CustomDataDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getCustomData = (params: RequestParams = {}) =>
        this.http.request<CustomDataDto, Error>({
            path: `/info`,
            method: 'GET',
            ...params,
        })
}
