import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import { clientNotificationController } from '../../dao/notificationController'
import withSession from '../../lib/session'
import styles from '../../styles/m/login.module.css'
import '../../support/bridge'
import { login } from '../api/login'
import httpClient from '../../dao/http-clients/httpClient'

declare global {
    // ⚠️ notice that "Window" is capitalized here
    interface Window {
        settingSaveInfoForIos: any;
    }
}

const LoginPage = () => {
    const router = useRouter()
    const [error, setError] = useState(false)
    const [inspection, setInspection] = useState(false)
    const [cookies, setCookies] = useCookies(['isSave', 'saveId', 'savePw'])
    const [isSave, setIsSave] = useState(cookies.isSave ? cookies.isSave : 'false')
    const [inputText, setInputText] = useState()
    const [isChangeInputText, setIsChangeInputText] = useState(false)
    const onChangeInputText = (e) => {
        setInputText(e.target.value)
    }
    useEffect(() => {
        if (error) {
            setIsChangeInputText(true)
        }
    }, [inputText])

    const { register, handleSubmit, setValue } = useForm({ defaultValues: { userId: '', userPw: '' } })

    useEffect(() => {
        if (window?.webkit) {
            window?.webkit?.messageHandlers?.getToken?.postMessage('')
            window?.webkit?.messageHandlers?.getSaveInfo?.postMessage('')
        } else if (window?.android) {
            window?.android?.getToken('')
            window?.android?.getSaveInfo()
        }
    }, [])

    const [saveCheck, setSaveCheck] = useState(false)
    useEffect(() => {
        if (isSave == 'true') {
            setSaveCheck(true)
            setValue('userId', cookies.saveId)
            setValue('userPw', cookies.savePw)
        }
    }, [])
    // mobile 아이디 저장시 실행됨.
    useEffect(() => {
        // AOS 용
        const infoCallback = async (event) => {
            if (event.detail.isSave === 'true') {
                setSaveCheck(true)
                setValue('userId', event.detail.saveId)
                setValue('userPw', event.detail.savePw)
            }
        }
        window.addEventListener('settingSaveInfo', infoCallback)

        // IOS 용
        window.settingSaveInfoForIos =(id:string, pw:string)=>{
            setSaveCheck(true)
            setValue('userId',id)
            setValue('userPw',pw)
        }
    },[])

    const onSubmit = async (submitData) => {
        const { userId, userPw } = submitData
        const check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
        if (check.test(userId)) {
            setError(true)
            setInspection(false)
            return
        }

        try {
            const result = await login(userId, userPw, window?.bridge?.info?.token || '')
            httpClient.setSecurityData(result.data.access_token)

            if (window?.bridge?.info?.token) {
                await clientNotificationController.register({
                    token: window?.bridge?.info?.token,
                    loginId: userId,
                })
            }

            if (window?.webkit) {
                window?.webkit?.messageHandlers?.saveLogin?.postMessage(result.data)
                if (saveCheck) {
                    const saveInfo = {
                        saveId: userId,
                        savePw: userPw,
                    }
                    window?.webkit?.messageHandlers?.saveInfo?.postMessage(saveInfo)
                } else {
                    window?.webkit?.messageHandlers?.saveDelete?.postMessage('')
                }
            } else if (window?.android) {
                window?.android?.saveLogin(result.data)
                if (saveCheck) {
                    window?.android?.saveInfo(userId, userPw)
                } else {
                    window?.android?.saveDelete()
                }
            } else {
                if (saveCheck) {
                    setCookies('isSave', 'true')
                    setCookies('saveId', userId)
                    setCookies('savePw', userPw)
                } else {
                    setCookies('isSave', 'false')
                    setCookies('saveId', '')
                    setCookies('savePw', '')
                }
            }

            if (/Mobi|Android/i.test(navigator.userAgent)) {
                if(result.data.userData.isCredentialsNonExpired) {
                    await router.push('/m/home')
                } else {
                    await router.push('/m/setting/password')
                }
            } else {
                if(result.data.userData.isCredentialsNonExpired) {
                    await router.push('/web/home')
                } else {
                    await router.push('/web/setting/password')
                }
            }
        } catch (e: any) {
            if (e.toString().indexOf(503) > -1) {
                setInspection(true)
                setError(false)
            } else {
                setInspection(false)
                setError(true)
                setIsChangeInputText(false)
            }
        }
    }

    return (
        <form className={styles.wrapWhite} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.main}>
                <div
                    // src='/img/btn_back.png'
                    className={styles.header}
                    onClick={() => {
                        if (window?.webkit) {
                            window?.webkit?.messageHandlers?.gotoFirst?.postMessage('')
                        } else if (window?.android) {
                            window?.android?.gotoFirst('')
                        }
                    }}
                ></div>
                <div className={styles.top}>
                    <ul>
                        <li style={{ fontWeight: 'bolder', marginBottom: '10px', fontSize: '26px', color: '#333465' }}>
                            <img
                                src='/ctrl/api/v2/logo'
                                alt='logo'
                                width={120}
                                // className={styles.logo_img}
                            />
                        </li>
                        <li>
                            SMART OFFICE
                        </li>
                    </ul>
                </div>
                <div className={styles.contents}>
                    <input
                        className={styles.contents_input}
                        type='text'
                        placeholder='아이디를 입력해주세요.'
                        {...register('userId')}
                        onKeyDown={onChangeInputText}
                    />
                    <input
                        className={styles.contents_input}
                        type='password'
                        placeholder='비밀번호를 입력해주세요.'
                        {...register('userPw')}
                        onKeyDown={onChangeInputText}
                    />
                    <div className={styles.login}>
                        {error && !isChangeInputText && <div>아이디 혹은 비밀번호가 잘못되었습니다.</div>}

                        {inspection && `서버 긴급 점검 중 입니다.`}
                        <input type='submit' value='로그인' />
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '20px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{ fontSize: '12px', fontWeight: 'bold' }}>아이디 / 패스워드 저장</div>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                <input type='checkbox' checked={saveCheck} onChange={() => setSaveCheck(!saveCheck)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.bottom}>
                <ul>
                    <li>공지사항 바로가기</li>
                </ul>
            </div> */}
        </form>
    )
}

export const getServerSideProps = withSession(async function ({ req }) {
    req.session.destroy()
    return {
        props: {},
    }
})

export default LoginPage
