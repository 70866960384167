import { AxiosResponse } from 'axios'
import { NextApiResponse } from 'next'
import { LoginDto } from '../../../__generated__/data-contracts'
import loginController from '../../../dao/loginController'
import { HttpStatus } from '../../../domain/const'
import withSession, { NextIronRequest } from '../../../lib/session'
import { api } from '../../../utils/http'

export default withSession(async function handler(req: NextIronRequest, res: NextApiResponse): Promise<any> {
    const { method, body } = req
    if (method === 'POST') {
        const response: AxiosResponse<LoginDto> = await loginController.login(body)
        const data = response.data

        if (response.status === HttpStatus.UNAUTHORIZATION) {
            req.session.destroy()
            return res.status(response.status).json(data)
        } else if (response.status == HttpStatus.SERVICE_UNAVAILABLE) {
            return res.status(response.status).send('서버 긴급 점검')
        } else if (data.userData) {
            req.session.set('user', data)
            await req.session.save()
            return res.status(HttpStatus.OK).json(data)
        } else {
            return res.status(response.status).json(data)
        }
    } else if (method === 'DELETE') {
        await req.session.destroy()
        return res.status(HttpStatus.OK).json({})
    } else if (method === 'GET') {
        const user = req.session.get('user')

        if (user) {
            return res.status(HttpStatus.OK).json(user)
        } else {
            return res.status(HttpStatus.BAD_REQUEST).json(null)
        }
    }

    res.setHeader('Allow', ['POST'])
    return res.status(HttpStatus.METHOD_NOT_ALLOW).end(`Method ${method} Not Allowed`)
})

const login = (userName: string, password: string, token?: string) => {
    console.log(userName)
    return api.post('/api/login', { username: userName, password: password, token: token })
}

const logout = () => {
    return api.delete('/api/login')
}

export { login, logout }
